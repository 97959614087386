<template>
    <b-container fluid v-if="activities">
        <b-row>
            <b-col>
                <h1>Djelatnosti</h1>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <b-btn v-b-modal.addActivityModal
                       variant="primary"
                       class="mr-2">
                    Dodaj djelatnost
                </b-btn>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <osta-table position
                            :items="activities"
                            :fields="activitiesFields"
                            :actions="actions"
                            @onAction="action">
                </osta-table>
            </b-col>
        </b-row>
        <b-modal centered
                 ref="deleteActivity"
                 title="Potvrda"
                 @ok="deleteActivity(deleteId)"
                 @cancel="deleteId = 0"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati djelatnost</p>
        </b-modal>
        <b-modal centered
                 id="addActivityModal"
                 title="Dodaj djelatnost"
                 hide-footer>
            <b-form v-on:submit.prevent="addActivity">
                <b-form-group :label-cols-md="4"
                              label="Šifra"
                              label-for="code">
                    <b-form-input id="code"
                                  name="code"
                                  required
                                  type="text"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-form-group :label-cols-md="4"
                              label="Naziv"
                              label-for="name">
                    <b-form-input id="name"
                                  name="name"
                                  required
                                  type="text"
                                  size="sm"
                    ></b-form-input>
                </b-form-group>
                <b-btn variant="primary" type="submit" class="pull-right">Potvrdi</b-btn>
            </b-form>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: 'activitiesIndex',
        data() {
            return {
                activities: null,
                activitiesFields: [
                    {
                        key: 'code',
                        label: 'Kod',
                        sortable: true
                    },
                    {
                        key: 'name',
                        label: 'Naziv',
                        sortable: true
                    }
                ],
                actions: {
                    delete: {
                        name: 'Izbriši',
                        icon: 'fa fa-trash'
                    }
                },
                deleteId: 0
            }
        },
        methods: {
            action: function (action, item) {
                switch (action) {
                    case 'delete':
                        this.deleteId = item.id;
                        this.$refs.deleteActivity.show();
                        break;
                }
            },
            addActivity: function(e) {
                const formData = new FormData(e.target);
                axiOsta.post(api.activities, formData).then(response => {
                    if (response.status === 201) {
                        this.$router.go();
                    }
                });
            },
            deleteActivity: function(id) {
                axiOsta.delete(api.activities + id).
                then(response => {
                    this.$router.go();
                })
            }
        },
        created() {
            axiOsta.get(api.activities).then(response => {
                if (response.status === 200) {
                    this.activities = response.data;
                }
            })
        }
    }
</script>